@tailwind base;
@tailwind components;
@tailwind utilities;

/* PrimeReact and other global styles */
@import 'primereact/resources/themes/saga-blue/theme.css'; 
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #E2E2E2;
  overflow-x: hidden;
}

.bg-gradient-custom{
     background: linear-gradient(45deg, #d50036, #6c6c6c); 
}
.bg-gradient-custom-2{
  background: linear-gradient(45deg, #6c6c6c, #d50036); 
}
.mega-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background:transparent;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {

 
}

.swiper-slide {
  width: 100%!important;
  height: 400px;
}

.swiper-slide:nth-child(2n) {
  width: 95%!important;
  height: 400px;
}

.swiper-slide:nth-child(3n) {
  width: 95%!important;
  height: 400px;
}
.surface-section{
  background: #E2E2E2!important;
}
.border-bottom-custom{
  border-bottom: 1px solid #d30056;
  margin-left: 0.5px;
}
.border-custom{
  border: 1px solid #E2E2E2;
  border-radius: 2px;
}
.border-custom-2{
  border: 0.2px solid #d30056;
  border-radius: 10px;
}
.border-bottom-custom-2{
  border-bottom: 1px solid #ffffff;
  margin-left: 0.5px;
}
.border-bottom-custom-3{
  border-bottom: 1px solid #E2E2E2;
  margin-left: 0.5px;
}
.border-button{
  border: 2px solid #d30056;
  border-radius: 0.2rem;
}
.swiper-pagination-bullet-active {
  background: #d50036!important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 30px)!important;
}
.alternate-pricing .border-custom-2 {
  border: 0.2px solid #fff;
  border-radius: 10px;
}
.alternate-pricing h2, .alternate-pricing span {
color: #fff;
}

nav{
  border-bottom: #d4d4d5 solid 1px;
}

.p-breadcrumb {
  background-color: transparent;
}

.p-breadcrumb-list{
margin-left: 1.6rem;
}

.w-unset{
  width: unset!important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;

}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #d50036;

  color: #e3f2fd;
}

input{
  padding: 1rem!important;
    background-color: #f9f9f9;
}

/* Add this to your global CSS or inside a style tag in your component */
@media (max-width: 768px) {
  .p-steps {
    flex-direction: column;
    align-items: flex-start;
  }

  .p-steps-item {
    width: 100%;
    text-align: left;
    padding: 0.5rem 0;
  }

  .p-steps-number {
    display: none;
  }

  .p-steps-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Hide progress text in PrimeReact FileUpload */
.p-fileupload-progress {
  display: none;
}
